<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/finance_1' }"
          >代理记账服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>数电票详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <!-- <p>
          易联数电票是一款服务于中小微商业企业的轻量级自助开票应用，可以实现餐厅、酒店等行业顾客自助扫码开具数电发票。
          顾客扫描开票码，录入开票信息后一键提交开票申请，商户审核通过后系统将自动发送发票至邮箱。顾客申请后即可离开，无需等待，发票的开具与接收不受时间地点等因素影响，数电发票的形式也能有效避免丢失。
          商家无需安排发票专员，随时随地进行审核与确认，缓解营业高峰期开票压力，有效降低（打印、人工、物流等）成本。还可与商户支付机具或支付系统集成，实现支付结算与发票开具联动。
          易联数电票从根本上解决了开票流程繁琐、效率低下等问题，让发票开具更加便捷高效，是商户与顾客的双赢选择。
          欢迎详询，我们将竭诚为您服务！
          <span>产品特点：</span>
          <span>1.便捷性：无需排队领取、人工核验，只需手机即可查看、使用，极大提升了出行效率。</span>
          <span>2.环保：减少了纸质票证的使用，降低了对环境的影响，有助于实现绿色出行。</span>
          <span>3. 高效：实时更新，无需等待，让您随时随地享受顺畅的出行体验。 </span>
          <span>4.安全性：采用先进的数字加密技术，保障您的票据安全，避免假票、废票等问题。</span>
          我们拥有专业的客服团队，为您提供7x24小时的咨询与服务。如果您在使用过程中遇到任何问题，欢迎随时联系我们，我们将竭诚为您解答和解决。
          我们深知您的需求，我们将持续努力，为您提供更好的服务。如有任何建议或疑问，欢迎随时联系我们。
        </p> -->
        <div class="sdp_box">
          <div class="left_sdp_img">
            <img src="../../assets/img/finance_1/left_img.png" alt="" />
          </div>
          <div class="right_sdp">
            <div class="sdp_title">
              <span>数电票</span>
              <span class="line"></span>
            </div>
            <div class="sdp_content">
              易联数电票是一款服务于中小微商业企业的轻量级自助开票应用，可以实现餐厅、酒店等行业顾客自助扫码开具数电发票。
              顾客扫描开票码，录入开票信息后一键提交开票申请，商户审核通过后系统将自动发送发票至邮箱。顾客申请后即可离开，无需等待，发票的开具与接收不受时间地点等因素影响，数电发票的形式也能有效避免丢失。
              商家无需安排发票专员，随时随地进行审核与确认，缓解营业高峰期开票压力，有效降低（打印、人工、物流等）成本。还可与商户支付机具或支付系统集成，实现支付结算与发票开具联动。
              易联数电票从根本上解决了开票流程繁琐、效率低下等问题，让发票开具更加便捷高效，是商户与顾客的双赢选择。
              欢迎详询，我们将竭诚为您服务！
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">产品特点</div>
        <div class="sdp_td_content">
          <p>
            我们拥有专业的客服团队，为您提供7x24小时的咨询与服务。如果您在使用过程中遇到任何问题，欢迎随时联系我们，我们将竭诚为您解答和解决。
          </p>
          <p>
            我们深知您的需求，我们将持续努力，为您提供更好的服务。如有任何建议或疑问，欢迎随时联系我们。
          </p>
        </div>
        <div class="td_list">
          <div class="td_item">
            <div class="td_left">
              <div class="td_icon">
                <img src="../../assets/img/finance_1/sdp1.png" alt="" />
              </div>
              <div class="td_icon_title">便捷性</div>
            </div>
            <div class="td_right">
              <span>无需排队领取、人工核验，只需手机即可查看</span>
              <span>使用，极大提升了出行效率</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_left">
              <div class="td_icon">
                <img src="../../assets/img/finance_1/sdp2.png" alt="" />
              </div>
              <div class="td_icon_title">环 保</div>
            </div>
            <div class="td_right">
              <span>减少了纸质票证的使用，降低了对环境的影响</span>
              <span>有助于实现绿色出行</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_left">
              <div class="td_icon">
                <img src="../../assets/img/finance_1/sdp3.png" alt="" />
              </div>
              <div class="td_icon_title">安全性</div>
            </div>
            <div class="td_right">
              <span>采用先进的数字加密技术，保障您的票据安全</span>
              <span>避免假票、废票等问题</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_left">
              <div class="td_icon">
                <img src="../../assets/img/finance_1/sdp4.png" alt="" />
              </div>
              <div class="td_icon_title">高 效</div>
            </div>
            <div class="td_right">
              <span>实时更新，无需等待，让您随时随地享受顺畅的</span>
              <span>出行体验</span>
            </div>
          </div>
        </div>
        <div class="btn" @click="handleApply">立即体验</div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // subInfo() {
    //   this.$router.push({
    //     path: "/info",
    //     query: { org: "sz" },
    //   });
    // },
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "03";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

/* .box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
} */

/* .box p:first-child {
  padding-top: 0;
} */

/* .box p span {
  display: block;
  margin-bottom: 10px;
} */

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}

/* 新 */
.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.sdp_box {
  width: 100%;
  display: flex;
}

.left_sdp_img {
  width: 530px;
  height: 329px;
}

.left_sdp_img img {
  display: block;
  width: 100%;
}

.right_sdp {
  flex: 1;
  padding: 0 40px;
}

.sdp_title {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
}

.sdp_title .line {
  width: 75px;
  height: 6px;
  background: #055afe;
}

.sdp_content {
  font-size: 16px;
  color: #333333;
  line-height: 30px;
  margin-top: 20px;
  text-align: justify;
}

.sdp_td_content {
  font-size: 16px;
  color: #333333;
  margin-top: 20px;
  text-align: center;
}

.sdp_td_content p:last-child {
  margin-top: 10px;
}

.td_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
}

.td_item {
  width: 50%;
  height: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.td_left {
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #055afe;
  padding: 20px;
}

.td_icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.td_icon img {
  display: block;
  width: 100%;
}

.td_icon_title {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.td_right {
  width: 370px;
  height: 100%;
  background: #fff;
  font-size: 14px;
  color: #000000;
  padding: 20px 30px;
}

.td_right span {
  display: block;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}

.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
